import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { dateToLocal } from "../../../modules/date/date";
import { getOrders } from "../../../modules/orders/getOrders";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";
import ReactPaginate from "react-paginate";
import { BASE_URLS } from "../../../utils/api.urls";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getBuyerNames } from "../../../modules/orders/getBuyerNames";
import { setTrackingManually } from "../../../modules/orders/setTrackingManually";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import OrderNavPanel from "./OrderNavPanel";
import { setOrderFeedback } from "../../../modules/orders/setOrderFeedback";
import { orderCancelled } from "../../../modules/orders/orderCancelled";
import OrderFilter from "./OrderFilter";
import ContactBuyer from "./ContactBuyer";
import OrderCancelModel from "./OrderCancelModel";
import OrderFeedback from "./OrderFeedback";
// import TrackingModel from "./TrackingModel";
import NoteModel from "./NoteModel";
import { resetLogout } from "../../../modules/userLogin/resetLogout";
import { useNavigate } from "react-router-dom";
import { markAsShipped } from "../../../modules/orders/markAsShipped";
import SellerBreadCrumbs from "../../../components/sellerBreadCrumbs/SellerBreadCrumbs";
import Footer from "../../../components/footer/Footer";
import HeaderNavigationSeller from "../HeaderNavigationSeller";
import { CSVLink } from "react-csv";
import { getAllOrdersReport } from "../../../modules/orders/getAllOrdersReport";
import CreateShipmentModel from "./CreateShipmentModel";

const AllOrder = () => {
  const navigate       = useNavigate();
  const csvLink        = useRef<any>()
  let order            = useSelector<RootState, RootState["sellerOrders"]>(SellerOrdersResponseSelector);
  const commonResponse = useSelector<RootState, RootState["common"]>(CommonResponseSelector);
  
  const [pageCount,   setPageCount]   = useState(0);
  const [dropDown,    setDropDown]    = useState({show: false,Index: 0,});
  const [reportData,  setReportData]  = useState([] as any);
  const [itemCount,   setItemCount]   = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const [filter,      setFilter]      = useState({userId: "",orderId: "",startDate: "",endDate: "",});
  const [search,      setSearch]      = useState("");
  
  const isFirstRender  = useRef(true);
  const isFirstRender2 = useRef(true);
  const dispatch       = useDispatch();

  if (order?.sellerOrders?.data?.orders !== null && pageCount === 0) {
    setPageCount(Math.ceil(order?.sellerOrders?.data?.totalResult / itemCount));
  }

  const callOrders = () => {
    dispatch<any>(
      getOrders({
        currentPage : currentPage + 1,
        rows        : itemCount,
        userId      : filter.userId,
        orderId     : filter.orderId,
        StartDate   : filter.startDate,
        EndDate     : filter.endDate,
      })
    ).then(() => {
      if (currentPage === 0) setPageCount(0);
    });
    if (commonResponse?.common === null) dispatch<any>(getCommonData());
  };

  useEffect(() => {
    if (isFirstRender.current) {
      callOrders();
      dispatch<any>(getBuyerNames());
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (!isFirstRender2.current) {
      dispatch<any>(
        getOrders({
          currentPage : currentPage + 1,
          rows        : itemCount,
          userId      : filter.userId,
          orderId     : filter.orderId,
          StartDate   : filter.startDate,
          EndDate     : filter.endDate,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);

  useEffect(() => {
    if (order?.sellerOrdersReport) {
      let userData: any = [];
      order?.sellerOrdersReport?.data?.orders?.map((order:any) => {
        let datalist: any = {};
        
        datalist["orderId"]        = order?.orderId;
        datalist["buyerName"]      = order?.buyerName;
        datalist["productName"]    = order?.productName;
        datalist["condition"]      = order?.condition;
        datalist["quantity"]       = order?.quantity;
        datalist["stockAvailable"] = order?.stockAvailable;
        datalist["soldFor"]        = order?.soldFor;
        datalist["total"]          = order?.total;
        datalist["trackingNo"]     = order?.trackingNo;
        datalist["orderDate"]      = order.orderDate !== null ? dateToLocal(order.orderDate) : "";
        datalist["buyerPaidDate"]  = order.buyerPaidDate !== null ? dateToLocal(order.buyerPaidDate) : "";
        datalist["zipCode"]        = order?.zipCode;
        datalist["status"]         = order?.status;
        datalist["shippingAmount"] = order?.shippingAmount;
        datalist["sellerNote"]     = order?.sellerNote;
        datalist["shippingDate"]   = order.shippingDate !== null ? dateToLocal(order.shippingDate) : "";
        
        userData.push(datalist);
      });
      setReportData(userData);
    } 
  }, [order?.sellerOrdersReport]);

  useEffect(()=>{

    if(reportData.length>0){
      csvLink.current.link.click()}
  },[reportData])

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handleFilterChange = (event: any) => {
    setcurrentPage(0);
    if (event.target.name === "orderId")
      setFilter({ ...filter, orderId: search });
    else setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  
  const resetFilter = () => {
    setcurrentPage(0);
    setFilter({ userId: "", orderId: "", startDate: "", endDate: "" });
    setSearch("");
  };

  interface trackingValues {
    visible: string;
    orderId: string;
    ManualCourierId: string;
    trackingNo: string;
    ManualCourierName: string | null;
  }
  interface feedbackValues {
    visible: string;
    orderId: string;
    definedMessage: string | null;
    message: string;
  }
  interface ContactBuyerValues {
    message: string;
    orderId: string;
    sendCopy: boolean;
    visible: string;
  }
  interface NoteValues {
    visible: string;
    orderId: string;
    Note: string;
  }
  interface cancelValues {
    visible: string;
    orderId: string;
    reason: string | null;
    description: string | null;
  }

  interface createShipmentState {
    visible             : string,
    ShippingDateAndTime : string,
    orderId             : string,
    ShipmentDescription : string,
    date                : string,
    manualShipment      : boolean,
  }

  const [tracking, setTracking] = useState<trackingValues>({
    visible: "none",
    orderId: "",
    ManualCourierId: "",
    trackingNo: "",
    ManualCourierName: null,
  });

  const [contactBuyer, setContactBuyer] = useState<ContactBuyerValues>({
    visible: "none",
    orderId: "",
    message: "",
    sendCopy: false,
  });

  const [note, setNote] = useState<NoteValues>({
    visible: "none",
    orderId: "",
    Note: "",
  });

  const [feedback, setFeedback] = useState<feedbackValues>({
    visible: "none",
    orderId: "",
    definedMessage: null,
    message: "",
  });

  const [createShipment, setCreateShipment] = useState<createShipmentState>({
    visible             : "none",
    ShippingDateAndTime : "",
    orderId             : "",
    ShipmentDescription : "",
    date                : "",
    manualShipment      : false,
  });

  const [cancelOrder, setCancelOrder] = useState<cancelValues>({
    visible: "none",
    orderId: "",
    reason: null,
    description: null,
  });

  useEffect(() => {
    if (order.trackingManually != null) {
      if (order.trackingManually.success == "Y") {
        notify(order.trackingManually.message, constant.SUCCESS);
        callOrders();
      } else if (order.trackingManually.success == "N") {
        notify(order.trackingManually.message, constant.ERROR);
      }
      dispatch<any>(resetOrder());
      setTracking({
        visible: "none",
        orderId: "",
        ManualCourierId: commonResponse?.common?.manualCouriers[0].value.toString(),
        trackingNo: "",
        ManualCourierName: null,
      });
    }
  }, [order.trackingManually]);
  
  useEffect(() => {
    if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);
        callOrders();
      } else if (order.saveData.success == "N") {
        notify(order.saveData.message, constant.ERROR);
      }

      setFeedback({
        visible: "none",
        orderId: "",
        definedMessage: null,
        message: "",
      });
      setCancelOrder({
        visible: "none",
        orderId: "",
        description: null,
        reason: null,
      });
      setCreateShipment({
        visible: "none",
        orderId: "",
        ShipmentDescription: "",
        ShippingDateAndTime: "",
        date:"",
        manualShipment : false
      });
      setContactBuyer({
        visible: "none",
        orderId: "",
        message: "",
        sendCopy: false,
      });
      setNote({
        visible: "none",
        orderId: "",
        Note: "",
      });
    }
    if (order.saveDataError !== null) {
      notify(order.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetOrder());
  }, [order.saveData, order.saveDataError]);
  useEffect(() => {
    if (commonResponse?.common?.manualCouriers !== null) {
      setTracking({
        ...tracking,
        ManualCourierId:
          commonResponse?.common?.manualCouriers[0].value.toString(),
      });
    }
  }, [commonResponse.common]);

  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
      <section className="seller-breadcrumb-block">
      <div className="container">
        <ul>
          <li className="text">Orders</li> 
          <li className="pipe">|</li>
          <li className="text-link"><a href="#">{localStorage.getItem("UserName")}</a></li>
        </ul>
      </div>
    </section>

    <HeaderNavigationSeller currentPage="Order" />
    <OrderCancelModel cancelOrder={cancelOrder} setCancelOrder={setCancelOrder} commonResponse={commonResponse} />
    <CreateShipmentModel createShipment={createShipment} setCreateShipment={setCreateShipment} />
    <ContactBuyer contactBuyer={contactBuyer} setContactBuyer={setContactBuyer} />

      {/* <TrackingModel
        tracking={tracking}
        setTracking={setTracking}
        commonResponse={commonResponse}
      /> */}

      {/* <OrderFeedback
        feedback={feedback}
        setFeedback={setFeedback}
        commonResponse={commonResponse}
      /> */}

      <NoteModel note={note} setNote={setNote} />
      <main
        className="seller-page-section"
        onClick={() =>
          dropDown.show &&
          setDropDown({
            ...dropDown,
            show: false,
          })
        }
      >
        <div className="container seller-orders-section">
          <div className="row">
            <OrderNavPanel currentPage="AllOrder" />
            <div className="col-md-10">
              <OrderFilter
                setFilter={setFilter}
                filter={filter}
                order={order}
                handleFilterChange={handleFilterChange}
                setSearch={setSearch}
                search={search}
                resetFilter={resetFilter}
              />
              <div className="filter-result mt-3">
                <div className="total-result d-flex justify-content-between">
                  <h5>
                    Results: {order?.sellerOrders?.data?.totalResult ? order?.sellerOrders?.data?.totalResult : 0} ($
                    {order?.sellerOrders?.data?.totalAmount ? order?.sellerOrders?.data?.totalAmount : 0})
                  </h5>
               { order?.sellerOrders?.data?.totalResult &&  <ul className="d-flex">
                    <li>
                      <span style={{cursor:"pointer", color:"blue"}}  onClick={()=>
                          navigate("/seller/printOrders", {
                            state: {currentPage: 1,
                              rows:order?.sellerOrders?.data?.totalResult ,
                              userId: filter.userId,
                              orderId: filter.orderId,
                              StartDate: filter.startDate,
                              EndDate: filter.endDate,
                              type:"",},
                          })
                         } >Print</span>
                    </li>
                    <li className="pipe">|</li>
                    <li>
   
                      <span style={{cursor:"pointer", color:"blue"}} onClick={()=>dispatch<any>(
      getAllOrdersReport({
        currentPage: 1,
        rows:order?.sellerOrders?.data?.totalResult ,
        userId: filter.userId,
        orderId: filter.orderId,
        StartDate: filter.startDate,
        EndDate: filter.endDate,
        type:""
      }))} >Download reports</span>
      <CSVLink
         data={reportData}
         filename='report.csv'
         className='hidden'
         onClick={() =>
          dispatch<any>(resetOrder("reportAllOrder"))
            .then({
              done() {},
            })
        }
         headers={constant?.SELLER_ALL_ORDERS_COLUMNS}
         ref={csvLink}
         target='_blank'
      />
                    </li>
                  </ul>}
                </div>
                {/* <div className="result-type d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="form-group">
                      <select className="form-control input-seller">
                        <option>Select</option>
                        <option>Shipping</option>
                      </select>
                    </div>
                    <div className="form-group ml-3">
                      <select className="form-control input-seller">
                        <option>Select</option>
                        <option>Relist</option>
                      </select>
                    </div>
                    <div className="form-group ml-3">
                      <select className="form-control input-seller">
                        <option>Select</option>
                        <option>Leave feedback</option>
                      </select>
                    </div>
                    <div className="form-group ml-3">
                      <select className="form-control input-seller">
                        <option>Select</option>
                        <option>More</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="form-group">
                      <select className="form-control input-seller">
                        <option>Select</option>
                        <option>Date Sold</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="result-table-wrapper">
                  <table className="result-table">
                    <thead>
                      <tr>
                        <th className="">Action</th>
                        <th className="order-width">Order details</th>

                        <th>Status</th>

                        <th>Qty</th>
                        <th>Sold for</th>
                        <th>Shipping cost</th>

                        <th>Total</th>
                        <th>Shipment created</th>

                        <th>Tracking no</th>

                        <th className="date-width">Sale date</th>
                        <th>Buyer paid date</th>
                        <th>Zip code</th>
                        <th className="order-width">Notes</th>
                        {/* <th>Engage Buyer</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {order?.sellerOrders?.data?.orders?.map(
                        (order: any, Index: number) => (
                          <tr key={Index}>
                            <td className="action">
                              {/* Received */}
                              <div className="dropdown d-inline-block ml-3 more-option-dropdown">
                                <button
                                  className="btn btn-multiple-option dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={() =>
                                    setDropDown({
                                      show: !dropDown.show,
                                      Index: Index,
                                    })
                                  }
                                ></button>
                                <div
                                  className={`dropdown-menu${
                                    dropDown.show && dropDown.Index == Index
                                      ? " show"
                                      : ""
                                  }`}
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  {/* <div
                                    className="dropdown-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setFeedback({
                                        ...feedback,
                                        visible: "block",
                                        orderId: order.orderId,
                                      });
                                      setDropDown({
                                        ...dropDown,
                                        show: !dropDown.show,
                                      });
                                    }}
                                  >
                                    Leave feedback
                                  </div> */}
                                  {/* {(order.isManualTrackingNo === null ||
                                    order.isManualTrackingNo === true) &&
                                    order.status !== "Cancelled" &&
                                    order.status !== "Delivered" &&
                                    order.status !== "Shipped" && (
                                      <div
                                        className="dropdown-item"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setTracking({
                                            ...tracking,
                                            visible: "block",
                                            orderId: order.orderId,
                                          });

                                          setDropDown({
                                            ...dropDown,
                                            show: !dropDown.show,
                                          });
                                        }}
                                      >
                                        Add Tracking number
                                      </div>
                                    )} */}
                                  {/* {order.status === "New" && (
                                    <div
                                      className="dropdown-item"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        dispatch<any>(
                                          markAsShipped({
                                            orderId: order.orderId,
                                          })
                                        )
                                      }
                                    >
                                      Mark as shipped
                                    </div>
                                  )} */}
                                  {order.status !== "Cancelled" &&
                                    order.status !== "Delivered" &&
                                    order.status !== "Shipped" && (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="dropdown-item"
                                        onClick={() => {
                                          setCancelOrder({
                                            ...cancelOrder,
                                            visible: "block",
                                            orderId: order.orderId,
                                          });
                                          setDropDown({
                                            ...dropDown,
                                            show: !dropDown.show,
                                          });
                                        }}
                                      >
                                        Cancel order
                                      </div>
                                    )}
      { (!order?.shipmentCreated &&  order?.status!=="Cancelled") &&  <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="dropdown-item"
                                        onClick={() => {
                                          setCreateShipment({
                                            ...createShipment,
                                            visible: "block",
                                            orderId: order.orderId,
                                            ShipmentDescription:"",
                                            ShippingDateAndTime:"",
                                            date:"",
                                            manualShipment : (order?.isShipmentFromEfindIt)
                                          });
                                          setDropDown({
                                            ...dropDown,
                                            show: !dropDown.show,
                                          });
                                        }}
                                      >
                                        Create shipment
                                      </div>}
                                  <div
                                    className="dropdown-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setNote({
                                        ...note,
                                        visible: "block",
                                        orderId: order.orderId,
                                        Note:
                                          order.sellerNote !== null
                                            ? order.sellerNote
                                            : "",
                                      });
                                      setDropDown({
                                        ...dropDown,
                                        show: !dropDown.show,
                                      });
                                    }}
                                  >
                                    Add/ Edit note
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setContactBuyer({
                                        ...contactBuyer,
                                        visible: "block",
                                        orderId: order.orderId,
                                      });
                                      setDropDown({
                                        ...dropDown,
                                        show: !dropDown.show,
                                      });
                                    }}
                                  >
                                    Contact buyer
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      navigate("/seller/reportBuyer", {
                                        state: order,
                                      });
                                    }}
                                  >
                                    Report buyer
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (order.status == "Cancelled")
                                        navigate("/seller/cancelOrderDetail", {
                                          state: order.orderId,
                                        });
                                      else
                                        navigate("/seller/orderDetail", {
                                          state: order.orderId,
                                        });
                                    }}
                                  >
                                    View order details
                                  </div>
                                  {order?.shipmentCreated &&  <div
                                    className="dropdown-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                     
                                        navigate("/seller/orderDetailsShipment/", {
                                          state: {orderId:order.orderId , status :order?.status},
                                        });
                                    }}
                                  >
                                    View shipment details
                                  </div>}
                                </div>
                              </div>
                            </td>
                            <td className="order-details">
                              <div>
                                <p>
                                  <span>{order.orderId}</span>{" "}
                                  <span>{order.buyerName}</span>
                                </p>
                                <div className="d-flex product-information">
                                  <figure>
                                    <img
                                      src={
                                        BASE_URLS.AWS_URL +
                                        "/Thumb" +
                                        order.productImage
                                      }
                                      alt="dummy image"
                                      style={{
                                        height: 40,
                                        width: 40,
                                      }}
                                    />
                                  </figure>
                                  <div className="product-detail">
                                    <h6>{order.productName}</h6>
                                    <p>Condition: {order.condition}</p>
                                    {/* {(order.isManualTrackingNo === null ||
                                      order.isManualTrackingNo === true) && (
                                      <button
                                        className="btn btn-optional"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        onClick={() =>
                                          setTracking({
                                            ...tracking,
                                            visible: "block",
                                            orderId: order.orderId,
                                          })
                                        }
                                      >
                                        + Add Tracking
                                      </button>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>{order.status}</td>
                            <td className="qty">
                              <span>{order.quantity}</span> (Available{" "}
                              {order.stockAvailable})
                            </td>
                            <td className="sold-for">${order.soldFor}</td>
                            <td className="total">${order.shippingAmount}</td>

                            <td className="total">${(order.total+order.shippingAmount).toFixed(2)}</td>
                            <td className="">{order.shipmentCreated ? "Yes" :"No"}</td>

                            <td className="total">{order.trackingNo}</td>

                            <td className="date-sold">
                              {dateToLocal(order.orderDate)}
                            </td>
                            <td className="date-buyer-paid">
                              {order.buyerPaidDate !== null
                                ? dateToLocal(order.buyerPaidDate)
                                : ""}
                            </td>
                            <td className="zip-code">{order.zipCode}</td>
                            <td className="text-break">{order.sellerNote}</td>

                            {/* <td className="engage-buyer">
                              <a href="">Send Coupon</a>
                            </td> */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <nav className="pagination-block ">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination justify-content-center mt-4"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  <div className="total-items-block">
                    <label>Items per page:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleItemsChange}
                      value={itemCount}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default AllOrder;
