import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getProductCategoryPage } from "../../../modules/buyer/product/getProductCategoryPage";
import { buyerProductSelector } from "../../../modules/buyer/product/selectors";
import { RootState } from "../../../utils/types";
import BuyerFooter from "../buyerFooter";
import BuyerHeader from "../BuyerHeader";
import DefaultImage from "../../../assets/images/defaultImage.png";
import { BASE_URLS } from "../../../utils/api.urls";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const ProductCategory = () => {
  const dispatch = useDispatch();
  const { Id } = useParams();
  const { name } = useParams();

  let buyerProduct = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );

  useEffect(() => {
    if (Id !== undefined) {
      dispatch<any>(getProductCategoryPage(Id));
    }
  }, [Id, name]);
  const [subCategory, setSubCategory] = useState<any>();
  return (
    <DefaultLayout>

      <BuyerHeader currentPage={name} subCategory={subCategory} />
      <ToastContainer/>
      <section className="brecrumb">
        <div className="container">
          <nav className="nav-2">
            <ul>
              <li>
                <Link to="/home">eFindit</Link>
              </li>
              <li>
                <a href="#">{name}</a>
              </li>
            </ul>
          </nav>
        </div>
      </section>

      <main className="min-section">
        <div className="filterBtn">
          <img src="images/filter-filled-tool-symbol.png" alt="" />
        </div>
        <div className="container">
          <section className="withFilter">
            <div className="left-filter">
              <div className="closeBtn"></div>
              <div className="filterWrap">
                <p className="filter-main-title">Shop by category</p>
             { buyerProduct?.productCategoryPage?.data &&  <div className="filters">
                  <div className="filterList">
                    <button className="toggleBtn"></button>
                    {/* <div className="filter-title">
                      Cell Phones, Smart Watches, & Accessories
                    </div> */}
                    <div className="filter-drop">
                      <ul>
                        {buyerProduct?.productCategoryPage?.data?.map(
                          (category, Index) => (
                            <li>
                              <a
                                href="#"
                                onClick={() => setSubCategory(category)}
                              >
                                {category.name}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>}

                {/* <div className="topBrands">
                  <p className="filter-main-title border-0 m-0">
                    Shop by category
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      <a href="">Alienware</a>
                    </li>
                    <li>
                      <a href="">Apple</a>
                    </li>
                    <li>
                      <a href="">ASUS</a>
                    </li>
                    <li>
                      <a href="">Beats by Dr. Dre</a>
                    </li>
                    <li>
                      <a href="">Bose</a>
                    </li>
                    <li>
                      <a href="">Canon</a>
                    </li>
                    <li>
                      <a href="" className="viewMore">
                        View more
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>

            <div className="right-wrapper">
              {/* <div className="banner">
                <div className="content">
                  <p className="text-10">
                    Refurbished
                    <br />
                    All the Smartphones
                  </p>
                  <p className="text-11">
                    Apple, Samsung, LG, and more.
                    <br />
                    All from our trusted sellers.
                  </p>
                  <a className="viewMoreBtn">
                    View Now
                    <img
                      src="images/Shape 5 copy 2.png"
                      alt=""
                      width="11"
                      height="16"
                    />
                  </a>
                </div>
              </div> */}
              <div className="category">
                <h3 className="text-uppercase">SHOP BY CATEGORY</h3>
                <div className="category-wrap">
                  {buyerProduct?.productCategoryPage?.data?.map(
                    (category, Index) => (
                      <div
                        key={category.id}
                        className="categoryList"
                        onClick={() => setSubCategory(category)}
                      >
                        <figure>
                          <a href="#">
                            <img
                              src={BASE_URLS.AWS_URL + category.imgName}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DefaultImage;
                              }}
                              alt="Category"
                            />
                          </a>
                        </figure>
                        <h3>
                          <a href="">{category.name}</a>
                        </h3>
                      </div>
                    )
                  )}
                  {buyerProduct?.productCategoryPage?.data == null && (
                    <div className="h2-block">
                      <h2 className="h2-section-title">No records found</h2>
                    </div>
                  )}
                </div>
              </div>

              {/* <section className="find-section mb-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="h2-block pt-0">
                      <h2 className="h2-section-title">Shop by Interest</h2>
                    </div>

                    <div className="swiper-container swiper findSwiper">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide ">
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <img
                                  src="images/1617574319_485_Apple-iPhone-11-PNG-Photos.png"
                                  alt="Category"
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">Apple</a>
                            </h4>
                          </div>
                        </div>

                        <div className="swiper-slide ">
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <img src="images/f2.jpg" alt="Category" />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">Virtual Reality</a>
                            </h4>
                          </div>
                        </div>

                        <div className="swiper-slide ">
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <img src="images/f3.jpg" alt="Category" />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">Remote Control Toys</a>
                            </h4>
                          </div>
                        </div>

                        <div className="swiper-slide ">
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <img src="images/f4.jpg" alt="Category" />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">Gadgets</a>
                            </h4>
                          </div>
                        </div>

                        <div className="swiper-slide ">
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <img src="images/f5.jpg" alt="Category" />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">3D Printing</a>
                            </h4>
                          </div>
                        </div>

                        <div className="swiper-slide ">
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <img src="images/f6.jpg" alt="Category" />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">Furniture & Lights</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </div>
          </section>
        </div>
      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default ProductCategory;
