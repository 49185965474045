import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { getBids } from "../../../../../modules/buyer/myEfindit/activity/getBids";
import { getOffers } from "../../../../../modules/buyer/myEfindit/activity/getOffers";
import { getPurchaseHistory } from "../../../../../modules/buyer/myEfindit/activity/getPurchaseHistory";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { dateToLocal } from "../../../../../modules/date/date";
import { notify } from "../../../../../modules/notification/notify";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import NoteBidsOffersModel from "../NoteBidsOffersModel";
import "react-toastify/dist/ReactToastify.css";
import { resetDetail } from "../../../../../modules/buyer/product/resetDetail";
import { Link, useNavigate } from "react-router-dom";
import { deleteOffers } from "../../../../../modules/buyer/myEfindit/activity/deleteOffers";
import moment from "moment";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../../../../utils/helpers";
import { postAcceptRejectCounter } from "../../../../../modules/buyer/myEfindit/activity/api";
import { buyerAcceptRejectOffer } from "../../../../../modules/buyer/myEfindit/activity/buyerAcceptRejectOffer";
import MakeCounterOfferModel from "./MakeCounterOfferModel";
import ReviewOfferModel from "../../../productDetail/ReviewOfferModel";

const BidsOffers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  const [checked, setChecked] = useState("Bids");
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>();
  const [makeOffer, setMakeOffer] = useState({
    quantity:0,
    buyNowPrice:0,
    visible: "none",
    offerId:0,
    // previousOffer:0,
    // quantity:0,
    offerAmount:"",
  });
  const[reviewOffer, setReviewOffer] =useState({
    visible:"none"
  })
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setIsCheckAll(!isCheckAll);

    if (checked) {
      setIsCheck(
        activityResponse?.offers?.data?.offers?.map((li) => li.id)
      );
    } else setIsCheck([]);
  };
  const handleDeleteAll = () => {
    dispatch<any>(
      deleteOffers({
        ids: isCheck,
      })
    );
  };
  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    if (
      checked &&
      isCheck.length === activityResponse?.offers?.data?.offers.length - 1
    ) {
      setIsCheckAll(true);
    }
    setIsCheck([...isCheck, Number(id)]);
    if (!checked) {
      setIsCheckAll(false);

      setIsCheck(isCheck.filter((item: any) => item !== Number(id)));
    }
  };

  interface NoteValues {
    visible: string;
    Id: string | number;
    Note: string;
  }
  const [note, setNote] = useState<NoteValues>({
    visible: "none",
    Id: "",
    Note: "",
  });

  if (activityResponse.bids !== null && checked === "Bids" && pageCount === 0) {
    setPageCount(
      Math.ceil(activityResponse?.bids?.data?.totalResult / itemCount)
    );
  }
  if (
    activityResponse.offers !== null &&
    checked === "Offers" &&
    pageCount === 0
  ) {
    setPageCount(
      Math.ceil(activityResponse?.offers?.data?.totalResult / itemCount)
    );
  }
  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const dateDifference = (date: any) => {
    // let date2: any = new Date(date);
    // date2 = new Date(date2.getTime() - date2.getTimezoneOffset() * 60 * 1000);
    // const date1: any = new Date();
    // const diffTime = Math.abs(date2 - date1);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // const hours = Math.ceil((diffTime / (1000 * 60 * 60)) % 24);
    // return diffDays + "d " + hours + "h  ";
    const startTime = moment.utc();
        let end = moment(date); 
        let duration = moment.duration(end.diff(startTime));
        let hours = duration.asHours();

        let Days = Math.floor(hours/24);
        let Remainder = hours % 24;
        let Hours = Math.floor(Remainder);

        // let dayText = Days == 1 ?" Day ":" Days ";

        let returnTime = Days == 0 ? Hours + " Hours" : Days + "d" + Hours + "h"

        return  returnTime;
  };
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(
        checked === "Bids"
          ? getBids({
              currentPage: currentPage + 1,
              rows: itemCount,
            })
          : getOffers({
              currentPage: currentPage + 1,
              rows: itemCount,
            })
      ).then(() => {
        window.scrollTo(0, 0);
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (activityResponse.noteSave != null) {
      if (activityResponse.noteSave.success == "Y") {
        notify(activityResponse.noteSave.message, constant.SUCCESS);
        dispatch<any>(
          checked === "Bids"
            ? getBids({
                currentPage: currentPage + 1,
                rows: itemCount,
              })
            : getOffers({
                currentPage: currentPage + 1,
                rows: itemCount,
              })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } else if (activityResponse.noteSave.success == "N") {
        notify(activityResponse.noteSave.message, constant.ERROR);
      }

      setNote({
        visible: "none",
        Id: "",
        Note: "",
      });
    }
    if (activityResponse.noteError !== null) {
      notify(activityResponse.noteError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.noteSave, activityResponse.noteError]);
  useEffect(() => {
    if (!isFirstRender2.current) {
      dispatch<any>(
        checked === "Bids"
          ? getBids({
              currentPage: currentPage + 1,
              rows: itemCount,
            })
          : getOffers({
              currentPage: currentPage + 1,
              rows: itemCount,
            })
      ).then(() => {
        window.scrollTo(0, 0);
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, checked]);


  useEffect(() => {
    if (activityResponse.saveDataProduct != null) {
      if (activityResponse.saveDataProduct.success == "Y") {
        notify(activityResponse.saveDataProduct.message, constant.SUCCESS);
        dispatch<any>(
          checked === "Bids"
            ? getBids({
                currentPage: currentPage + 1,
                rows: itemCount,
              })
            : getOffers({
                currentPage: currentPage + 1,
                rows: itemCount,
              })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } else if (activityResponse.saveDataProduct.success == "N") {
        notify(activityResponse.saveDataProduct.message, constant.ERROR);
      }
    }
    if (activityResponse.saveDataError !== null) {
      notify(activityResponse.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.saveDataProduct, activityResponse.saveDataError]);

  useEffect(() => {
    if (activityResponse.saveOfferCounter != null) {
      if (activityResponse.saveOfferCounter.success == "Y") {
        // notify(activityResponse.saveOfferCounter.message, constant.SUCCESS);
        dispatch<any>(
          checked === "Bids"
            ? getBids({
                currentPage: currentPage + 1,
                rows: itemCount,
              })
            : getOffers({
                currentPage: currentPage + 1,
                rows: itemCount,
              })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        }).then(()=> setReviewOffer({...reviewOffer, visible:"block"}));
      } else if (activityResponse.saveOfferCounter.success == "N") {
        notify(activityResponse.saveOfferCounter.message, constant.ERROR);
      }
    }
    if (activityResponse.saveOfferCounterError !== null) {
      notify(activityResponse.saveOfferCounterError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.saveOfferCounter, activityResponse.saveOfferCounterError]);

  return (
    <div>
      <DefaultLayout>
        <BuyerHeader subCategory={subCategory}  />
        <ToastContainer />
    <MakeCounterOfferModel 
  makeOffer={makeOffer}
  setMakeOffer={setMakeOffer}
  buyNowPrice={20}
    />
    <ReviewOfferModel
reviewOffer={reviewOffer}
setReviewOffer={setReviewOffer}
/>
        <NoteBidsOffersModel note={note} setNote={setNote} checked={checked} />
        <main className="min-section">
          <div className="container">
            <h1 className="sectionTitle mt-5">My eFindit</h1>

            <GroupTab currentPage="Activity" />
            <section className="withFilter">
              <ActivityNavPanel currentPage="Bids/Offers" />

              <div className="right-wrapper">
                <section className="deals-group pt-0">
                  <div className="deal-group-list four-col bgLight mt-0">
                    <div className="right-wrap-title-block">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                          <div className="right-wrap-title">Bids / Offers</div>
                        </div>
                        <div className="col-md-auto">
                          <div className="radio d-inline-block">
                            <input
                              id="radio-1"
                              name="radio"
                              type="radio"
                              defaultChecked={true}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setChecked("Bids");
                                  setcurrentPage(0);
                                }
                              }}
                            />
                            <label htmlFor="radio-1" className="radio-label">
                              Bids
                            </label>
                          </div>
                          <div className="radio d-inline-block ml-4">
                            <input
                              id="radio-2"
                              name="radio"
                              type="radio"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setChecked("Offers");
                                  setcurrentPage(0);
                                }
                              }}
                            />
                            <label htmlFor="radio-2" className="radio-label">
                              Your offers
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bidListCont">
                      <div className="table-responsive">
                      {checked === "Offers" &&
                            activityResponse?.offers?.data !== null && (
                        <div className="watch-list-delete-block">
                              <div className="custom-check">
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    name="selectAll"
                                    id="allchecked"
                                    onChange={handleSelectAll}
                                    checked={isCheckAll}
                                  />
                                  <label htmlFor="allchecked"></label>
                                </div>
                              </div>
                            <div>
                              {" "}
                              {/* <button className="addTolistBtn">
                                Add to list
                              </button> */}
                              <button
                                className="deleteBtn"
                                onClick={handleDeleteAll}
                                disabled={isCheck.length === 0 ? true : false}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                      )}
                        <div className="watch-list-block">
                          {checked === "Bids" &&
                            activityResponse?.bids?.data?.bids?.map(
                              (item, Index) => (
                                <div className="bids-row">

                                <div className="bids-content">
                                  <div className="bids-list-image-info">
                                    <div className="bids-image">
                                    <picture onClick={() =>
                                          dispatch<any>(
                                            resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}`))      
                                        }>
                                      <source srcSet={getWebPImage(item.defaultImage)} type="image/webp"/>
                                      <LazyLoadImage
                                        className="prod-img"
                                        src={BASE_URLS.AWS_URL +
                                          "/Thumb" +
                                          item.defaultImage}
                                          onError={imageOnError}
                                        />
                                    </picture>                                      
                                    </div>
                                  </div>

                                 <div className="bids-list-info">
                                  <div className="bids-title-content">
                                    <h4 className="itemTitle" style={{
                                            cursor: "pointer",
                                          }}   onClick={() =>
                            dispatch<any>(
                              resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}`))      
                          }>{item.title}</h4>
                                    {/* <div className="itemNumber">
                                      ( {item.productId} )
                                    </div> */}
                                    <div className="percentage spacer">
                                    <Link  to={`/buyer/sellerStore/${item.sellerId}`}>{item.storeName}</Link>

                                 
                                      <span>|</span>
                                      {item.yourTotalBids !== null && (
                                        <span>
                                        <span
                                          style={{
                                            color: "#3453c8",
                                          }}
                                        >
                                          {item.yourTotalBids}
                                        </span>
                                        <span>|</span>
                                        </span>
                                      )}
                                      {item?.feedback ? item?.feedback :"0"}%
                                    </div>
                                    <div className="max-bids">
                                      Your max bid: US ${item.yourMaxBids}
                                    </div>

                                    <div className="desc">
                                    <span className="text-break">{item.note}</span> 
                                      <br/>
                                      {(item.note ) && (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setNote({
                                              ...note,
                                              visible: "block",
                                              Id: item.productId,
                                              Note:
                                                item.note === null
                                                  ? ""
                                                  : item.note,
                                            });
                                          }}
                                          className="editNote"
                                        >
                                          Edit note
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="bids-win-content">
                                    {item.win ? (
                                      <div className="win">WIN</div>
                                    ) : moment.utc().isAfter(item.listingExpiryDate) ?  <div className="timeLeft" style={{color:"red"}}>
                                    Expired
                                  </div> :  (
                                      <div className="timeLeft">
                                        {dateDifference(item.listingExpiryDate)}
                                      </div>
                                    )}
                                    <div className="date">
                                      {item.listingExpiryDate !== null &&
                                        dateToLocal(item.listingExpiryDate)}
                                    </div>
                                  </div>
                                  <div className="bids-date-price">
                                    <div className="bid-price">
                                      ${item.buyNowPrice}
                                    </div>
                                    <div className="bids-done">
                                      <span style={{color:"blue"}}>{item.totalBids} bids</span>
                                      <span>+</span>shipping
                                    </div>
                                  </div>
                                  </div>
                                  </div>

                                  <div className="bids-buttons">
                                   
                              { item.win && <button onClick={()=>{
                                 navigate(
                                  "/buyer/checkout",
                                  {
                                    state: {ProductId: item.productId,
                                    VariantId: null,
                                    quantity:null,
                                    type:"Offer",
                                    CartIds: [
                                       
                                     
                                    ]},
                            
                                  }
                                )
                              }} className="viewSeller">
                                      Pay now
                                    </button>}
                                    <Link  to={`/buyer/sellerStore/${item.sellerId}`}className="viewSeller"> View seller's other items</Link>

                                    <button  onClick={() => setSubCategory({id:item?.categoryId, name:item?.categoryName})} className="blueBorderBtn">
                                      View similar items
                                    </button>
                                    {/* <div className="selectBox">
                                      <select name="" id="">
                                        <option value="">More Action</option>
                                        <option value="">More Action</option>
                                      </select>
                                    </div> */}
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setNote({
                                          ...note,
                                          visible: "block",
                                          Id: item.productId,
                                          Note:
                                            item.note === null ? "" : item.note,
                                        });
                                      }}
                                      className="blackBorderBtn"
                                    >
                                      Add note
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          {checked === "Offers" &&
                            activityResponse?.offers?.data?.offers?.map(
                              (item, Index) => (
                                <div className="offers-row">
                                 <div className="offers-content"> 

                                <div className="offer-list-image-info">
                                  <div className="watch-list-check">
                                  <div className="custom-check">
                                    <div className="wrap">
                                      <input
                                        type="checkbox"
                                        name={item.title}
                                        id={item.id.toString()}
                                        onChange={handleCheckboxClick}
                                        checked={isCheck.includes(
                                          item.id
                                        )}
                                      />
                                      <label
                                        htmlFor={item.id.toString()}
                                      ></label>
                                    </div>
                                  </div>
                                  </div>

                                  <div className="watch-list-image"> 
                                  <picture onClick={() =>
                                          dispatch<any>(
                                            resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}`))      
                                        }>
                                      <source srcSet={getWebPImage(item.defaultImage)} type="image/webp"/>
                                      <LazyLoadImage
                                        className="prod-img"
                                        src={BASE_URLS.AWS_URL +
                                          "/Thumb" +
                                          item.defaultImage}
                                          onError={imageOnError}
                                        />
                                    </picture>   
                                  </div>
                                </div>

                                  <div className="offers-list-info">
                                  <div className="offers-list-title-block">
                                    <h4 className="itemTitle"style={{
                                            cursor: "pointer",
                                          }}   onClick={() =>
                                            dispatch<any>(
                                              resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}`))      
                                          }   >{item.title}</h4>

                                    <div className="percentage spacer">
                                      <Link  to={`/buyer/sellerStore/${item.sellerId}`}>{item.storeName}</Link>
                                      <span>|</span> {item.feedback}%
                                    </div>
                                 
                                    <div className="itemNumber">

                                    <p> Quantity : {item.quantity}  </p>
                                     
                                     
                                      <p>  
                                        Status :  {item.isAccepted===true ? "Accepted" : item.isAccepted===false ? "Rejected" :item.isCounterOfferBySeller===true ?"Pending with you" : item.isPending===true ?"Pending with seller" :"Offer expired" }
                                      </p>        
                                    </div>
                                    <div className="itemNumber ">
                                      <span className="text-break">{item.note}</span>
                                      {(item.note) && (
                                        <span 
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setNote({
                                              ...note,
                                              visible: "block",
                                              Id: item.id,
                                              Note:
                                                item.note === null
                                                  ? ""
                                                  : item.note,
                                            });
                                          }}
                                          className="editNote"
                                        >
                                          Edit note
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="invalid-date-block">
                                    <div className="date">
                                      {item.createdOn !== null &&
                                        dateToLocal(item.createdOn)}
                                    </div>
                                  </div>

                                  <div className="bids-date-price">
                                    <div className="date">Offer price per item</div>
                                    <div className="bid-price">
                                      ${item.offerAmount}
                                    </div>
                                  {/* {item.finalOffer &&
                                  <>
                                  <div className="date">Final Offer</div>
                                    <div className="bid-price">
                                      {"$ "+ item.finalOffer }
                                    </div>
                                    </>
                                    } */}

                                  </div>
                                  </div>
                                  </div>

                                  <div className="offers-buttons ">

                              { item.isAccepted==true &&   <button onClick={()=>{
                                 navigate(
                                  "/buyer/checkout",
                                  {
                                    state: {ProductId: item.productId,
                                    VariantId: null,
                                    quantity:null,
                                    type:"Offer",
                                    CartIds: [
                                       
                                     
                                    ]},
                            
                                  }
                                )
                              }} className="viewSeller">
                                      Pay now
                                    </button>}
                                    { item?.isCounterOfferBySeller && 
                                      <>
                                      <button  onClick={() =>
                                  dispatch<any>(
                                    buyerAcceptRejectOffer({
                                      offerId: item?.id,
                                      accepted: true
                                    })
                                  )} className="buyNow">
                                             Accept
                                           </button>
                                           <button  onClick={() =>
                                  dispatch<any>(
                                    buyerAcceptRejectOffer({
                                      offerId: item?.id,
                                      accepted: false
                                    })
                                  )} className="buyNow">
                                                 Reject
                                               </button>
                                               <button onClick={()=>{
                                            setMakeOffer({
                                              ...makeOffer,
                                              visible: "block",
                                              quantity:item.quantity,
                                              buyNowPrice:item.buyNowPrice,
                                              offerId:item.id

                                             
                                            })
                                             }} className="buyNow">
                                                     Counter offer
                                                   </button>
                                                   </>
                                    }
                                      <Link  to={`/buyer/sellerStore/${item.sellerId}`}className="viewSeller"> View seller's other items</Link>
                                     
                                  
                                    <button  onClick={() => setSubCategory({id:item?.categoryId, name:item?.categoryName})} className="blueBorderBtn">
                                      View similar items
                                    </button>
                                    {/* <div className="selectBox">
                                      <select name="" id="">
                                        <option value="">More Action</option>
                                      
                                      </select>
                                    </div> */}
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setNote({
                                          ...note,
                                          visible: "block",
                                          Id: item.id,
                                          Note:
                                            item.note === null ? "" : item.note,
                                        });
                                      }}
                                      className="blackBorderBtn"
                                    >
                                      Add note
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          
                        </div>
                      </div>
                    </div>

                    <nav className="pagination-block">
                      <p className="total">
                        {checked === "Bids"
                          ? activityResponse?.bids?.data?.totalResult
                          : activityResponse?.offers?.data?.totalResult}{" "}
                        items total
                      </p>

                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "pagination justify-content-center mt-4"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      <div className="customSelect">
                        <label htmlFor="">Items per page :</label>
                        <div className="selectBox">
                          <select name="" id="" onChange={handleItemsChange}>
                            <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                          </select>
                        </div>
                      </div>
                    </nav>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </main>
        { (reviewOffer.visible==="block"  || makeOffer.visible=="block")  &&   <div className="modal-backdrop show"></div>}
      </DefaultLayout>
    </div>
  );
};

export default BidsOffers;
