import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import NoteBidsOffersModel from "../NoteBidsOffersModel";
import { notify } from "../../../../../modules/notification/notify";
import { constant } from "../../../../../utils/constant";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "../savedSellers/savedSellers.scss";
import "./savedSearch.scss";
import CROSS from "../../../../../assets/images/cross.png";

import { getSavedSellers } from "../../../../../modules/buyer/myEfindit/activity/getSavedSellers";
import { buyerProductSelector } from "../../../../../modules/buyer/product/selectors";
import { getSavedSearch } from "../../../../../modules/buyer/product/getSavedSearch";
import { removeSavedSearch } from "../../../../../modules/buyer/product/removeSavedSearch";
import { resetSave } from "../../../../../modules/buyer/product/resetSave";
import Footer from "../../../../../components/footer/Footer";
import ReactPaginate from "react-paginate";
import { deleteAllSaveSearch } from "../../../../../modules/buyer/product/api";
import { removeAllSavedSearch } from "../../../../../modules/buyer/product/removeAllSavedSearch";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {getWebPImageSavedSearch, imageOnError} from "../../../../../utils/helpers";

const SavedSearches = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);

  let productResponse = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);




 

  const callSavedSearches = () => {
    dispatch<any>(getSavedSearch({
      currentPage: currentPage + 1,
      rows: itemCount,
    })).then(() => {
      window.scrollTo(0, 0);

      if (currentPage === 0) setPageCount(0);
    });
  };
  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  useEffect(() => {
    if (!isFirstRender2.current) {
      callSavedSearches();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount]);

  useEffect(() => {
    if (productResponse.saveData != null) {
      if (productResponse.saveData.success == "Y") {
        notify(productResponse.saveData.message, constant.SUCCESS);
        callSavedSearches();
      } else if (productResponse.saveData.success == "N") {
        notify(productResponse.saveData.message, constant.ERROR);
      }

     
    }
    if (productResponse.saveDataError !== null) {
      notify(productResponse.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetSave());
  }, [productResponse.saveData, productResponse.saveDataError]);

  useEffect(() => {
    if (isFirstRender.current) {
      callSavedSearches();
    }
    isFirstRender.current = false;
  }, []);

  if (productResponse?.saveSearch !== null && pageCount === 0) {
    Math.ceil(productResponse?.saveSearch?.totalSavedSearchRecords/ itemCount)!==0 &&
    setPageCount(
      Math.ceil(productResponse?.saveSearch?.totalSavedSearchRecords/ itemCount)
    );
  }


  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
      
      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My eFindit</h1>

          <GroupTab currentPage="Activity" />
          <section className="withFilter">
            <ActivityNavPanel currentPage="SavedSearches" />

            <div className="right-wrapper">
          <section className="deals-group pt-0">
            <div className="deal-group-list four-col bgLight mt-0">
              <div className="right-wrap-title-block mb-0">
                <div className="row justify-content-between align-items-center">
                  <div className="col-md-auto">
                    <div className="right-wrap-title">Saved searches</div>
                  </div>
                  <div className="col-md-auto">
                        <button
                          type="button"
                          onClick={() => dispatch<any>(removeAllSavedSearch())}
                          className="clearBtn"
                          disabled={
                            productResponse?.saveSearch?.data === null
                              ? true
                              : false
                          }
                        >
                          Clear all
                        </button>
                      </div>
                </div>
              </div>
              <div className="bidListCont savedSearch">
                  <div className="saved-search-block">
                   
              {    productResponse?.saveSearch?.data?.savedSearchDetail?.map(
                          (item, Index) => (
                      <div className="saved-search-list" >
                          <div className="saved-search-image">
                            <div className="saved-image-box" onClick={()=> navigate("/buyer/searchResult", {
                        state: { savedSearch:item.filterSearches, subCategory:{id:item.filterSearches.categoryId,name:item.categoryName}},
                      })}>
                              
                              <picture>
                                  <source srcSet={getWebPImageSavedSearch(item.image)} type="image/webp"/>
                                  <LazyLoadImage
                                    className="prod-img"
                                    src={BASE_URLS.AWS_URL + item.image}
                                      onError={imageOnError}
                                    />
                              </picture>
                            </div>  
                          
                            <div className="saved-image-text">
                              <div className="saved-text-title">
                                {item.filterSearches.search!=="" &&   <p  className="text-title-main">{item.filterSearches.search}</p>}
                                  <p  className="text-title-sub">
                                    <span>{item.categoryName ? item.categoryName : "All categories"}</span>
                                   
                                  </p>
                                 
                              </div>
                              {/* <div className="btn btn-danger" onClick={()=> dispatch<any>(removeSavedSearch(item.id))}>Delete</div> */}
                              <button type="button" className="close saved-search-delete" aria-label="Close" onClick={()=> dispatch<any>(removeSavedSearch(item.id))}>
                                <span aria-hidden="true">&times;</span>
                              </button>
                            
                            </div>
                          </div>
                      </div>

                          ))}
{
  productResponse?.saveSearch?.data?.savedSearchDetail===null &&
  <div className="no-data-css">
  <h3>No records found</h3>
</div>
}
                  </div>
              </div>

              {productResponse?.saveSearch?.data?.savedSearchDetail!=null && (
              <nav className="pagination-block">
                    <p className="total">
                      {productResponse?.saveSearch?.totalSavedSearchRecords}{" "}
                      items total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
              )}
            </div>
          </section>

        </div>

      
          </section>
        </div>
      </main>

    <Footer/>
    </DefaultLayout>
  );
};
export default SavedSearches;
