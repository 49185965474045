import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import BuyerHeader from "../../../BuyerHeader";
import { RootState } from "../../../../../utils/types";
// import { SellerOrdersResponseSelector } from "../../../../../modules/orders/selectors";
import { BASE_URLS } from "../../../../../utils/api.urls";
import BuyerFooter from "../../../buyerFooter";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { getCourierServiceDetails } from "../../../../../modules/sellerProduct/getCourierServiceDetails";
import { SellerProductResponseSelector } from "../../../../../modules/sellerProduct/selector";
import { notify } from "../../../../../modules/notification/notify";
import { constant } from "../../../../../utils/constant";
// import { buyerReturnLabel } from "../../../../../modules/buyer/myEfindit/activity/buyerReturnLabel";
import { getRetunDetailBuyer } from "../../../../../modules/buyer/myEfindit/activity/getReturnDetailBuyer";
import DefaultImage from "../../../../../assets/images/defaultImage.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { getCourierCharges } from "../../../../../modules/buyer/myEfindit/activity/getCourierCharges";
import moment from "moment";
import { getSellerAddress } from "../../../../../modules/seller/getSellerAddress";
import { SellerResponseSelector } from "../../../../../modules/seller/selectors";
import { getReturnOrderTrackingDetails_Buyer } from "../../../../../modules/buyer/myEfindit/activity/getReturnOrderTrackingDetails_Buyer";
// import DatePicker from "react-datepicker";
import { createReturnShipment_Buyer } from "../../../../../modules/buyer/myEfindit/activity/createReturnShipment_Buyer";

const ReturnDetailBuyer = () => {
  const { state }             = useLocation();
  const returnId: any         = state;
  // const isFirstRender         = useRef(true);
  const dispatch              = useDispatch();
  // let order                   = useSelector<RootState, RootState["sellerOrders"]>(SellerOrdersResponseSelector);
  let activityResponse        = useSelector<RootState, RootState["activity"]>(activityResponseSelector);
  const sellerProductResponse = useSelector<RootState, RootState["sellerProduct"]>(SellerProductResponseSelector);
  const sellerResponse        = useSelector<RootState, RootState['sellers']>(SellerResponseSelector);

  const [sellerAddress,      setSellerAddr]         = useState<any>({});
  const [courierServiceData, setCourierServiceData] = useState<any>("");
  // const [shippingDate,       setShippingDate]       = useState<any>(null);
  const [trackingNumber,     setTrackingNumber]     = useState<any>("");

  useEffect(() =>{
    if(!sellerResponse) return;
    const {singleAddress} = sellerResponse;
    if(singleAddress?.success === 'Y'){
      setSellerAddr(singleAddress?.data);
    }
  },[sellerResponse])

  // const [courierService,   setCourierService]    = useState('');
  // const [pickupRadio,      setpickupRadio]       = useState('true');
  
  useEffect(() => {
    if(!activityResponse) return;
    
    const {saveDataSeller, saveDataError,manualReturnShipment} = activityResponse;
    
    if (saveDataSeller != null) {
      if (saveDataSeller.success == "Y") {
        notify(saveDataSeller.message, constant.SUCCESS);
        dispatch<any>(getRetunDetailBuyer( returnId));
      } else if (saveDataSeller.success == "N") {
        notify(saveDataSeller.message, constant.ERROR);
      }
    }

    if (saveDataError !== null) {
      notify(saveDataError, constant.ERROR);
    }

    if(manualReturnShipment != null){
      if(manualReturnShipment.success == "Y"){
        notify(manualReturnShipment.message, constant.SUCCESS);
        callingComponent();
      }else if(manualReturnShipment.success == "N"){
        notify(manualReturnShipment.message, constant.ERROR);
      }
    }

    if(activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping){
      dispatch<any>(getSellerAddress(returnId));
    }
    
    dispatch<any>(resetActivity("sellerFeedback"));
  }, [activityResponse]);

  useEffect(() => {
    if (state) {
      callingComponent()
    }
  }, [state]);

  const callingComponent = () =>{
    dispatch<any>(resetActivity("sellerFeedback"))
          .then(()=> dispatch<any>(resetActivity("orderDetails")))
          .then(dispatch<any>(getRetunDetailBuyer( returnId)));
      dispatch<any>(getCourierServiceDetails(returnId));
      dispatch<any>(getReturnOrderTrackingDetails_Buyer(returnId));
  }
  
  // const onCourierChange=(e:any)=>{
  //   const {value} = e.target
  //   setCourierService(value)
  //   value!=="" && dispatch<any>(getCourierCharges({returnId:returnId, courierServiceId:value}))
  // }

  const handleSubmit =()=>{
    // const now = new Date();
    // const timezoneOffsetMinutes = now.getTimezoneOffset();
    // const timezone= timezoneOffsetMinutes < 0 ? timezoneOffsetMinutes*-1 : timezoneOffsetMinutes;

    // dispatch<any>(buyerReturnLabel({
    //   // courierServiceId : courierService,
    //   returnId         : returnId,
    //   timezone         : timezone,
    //   IsPickUpRequired : pickupRadio==="true" ? true : false,
    // }))
    type payloadState = {
      // "ShippingDateAndTime" : string,
      "ReturnId"            : number,
      "TrackingNumber"      : string,
      "CourierProviderId"   : number
    }

    let payload:payloadState = {
      // "ShippingDateAndTime" : shippingDate,
      "ReturnId"            : returnId,
      "TrackingNumber"      : trackingNumber,
      "CourierProviderId"   : parseInt(courierServiceData)
    }
    
    if(
      (courierServiceData.length === 0 || trackingNumber?.length === 0 ) || 
      (
        (courierServiceData.length > 0 && courierServiceData == 1) && 
        (trackingNumber?.length > 0 && (trackingNumber?.length < 10 || trackingNumber?.length > 11))
      )
    ){
      console.log('error in payload');
    }else{
      console.log("no-error");
      dispatch<any>(createReturnShipment_Buyer(payload));
    }
  }

  // const dateCheck =(value:Date)=>{
  //   const date1 = moment(new Date());
  //   const date2 = moment(value);
    
  //   // Calculate the absolute difference in hours between the two dates
  //   const hoursDifference = Math.abs(date2.diff(date1, 'hours'));
    
  //   // Calculate the total difference in days and time
  //   const daysDifference = Math.floor(hoursDifference / 24);
    
  //   const remainingHours = hoursDifference % 24;
  //   if (daysDifference > 10 || (daysDifference === 10 && remainingHours > 0)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const convertAndDownloadPDF = (content: string, name: string, fileType:string) => {
    // Adjust MIME type based on file type
    const mimeType = fileType === 'PDF' ? 'application/pdf' : 'image/png';

    // Decode the base64 string into a Uint8Array
    const base64String = content.replace(/^data:[a-zA-Z]+\/[a-zA-Z]+;base64,/, ''); // Remove data URL prefix if present
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: mimeType });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}.${fileType}`;

    // Trigger a click event on the anchor element to initiate the download
    a.click();

    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
};



  const printPDF = (content:string) => {
    // Replace 'base64String' with your actual base64 string
    const base64String = content;

    // Decode the base64 string into a Uint8Array
    const bytes = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open a new window with the PDF for printing
    const printWindow:any = window.open(url);
    
    // Wait for the PDF to load and then trigger the print dialog
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        // Clean up by closing the print window and revoking the URL object
        printWindow.close();
        URL.revokeObjectURL(url);
      };
    };
  };
  
  // --------------------------------------------------------------------------------------------------------

  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
      
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li><Link to={"/"}>eFindit</Link></li>
            <li className="spacer">{">"}</li>
            <li><Link to={"/buyer/activity/purchaseHistory"}>purchase history</Link></li>
            <li className="spacer">{">"}</li>
            <li className="active">Return Detail</li>
          </ul>
        </div>
      </section>

      <main className="min-section">
        <div className="container">
          <section>
            <div className="row">
              <div className="col-xl-9">
                <div className="report mt-5">
                  <h1>Return Detail</h1>
                  <div className="prodInfo prod-info-block d-flex">
                    <div className="return-detail-left">
                      <div className="title ml-4 pb-2" style={{fontSize:20}}>{activityResponse?.returnDetailsBuyer?.data?.title}</div>
                      <div className="info">
                        <div className="row">
                          <div className="col-auto">
                            <div className="p-img ml-4" >
                              <img src={BASE_URLS.AWS_URL + "/Thumb" + activityResponse?.returnDetailsBuyer?.data?.defaultImage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = DefaultImage;
                                }} alt="" 
                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            {activityResponse?.returnDetailsBuyer?.data?.variant &&
                              JSON.parse(activityResponse?.returnDetailsBuyer?.data?.variant)?.map((product:any)=>
                                ( <p  style={{fontSize:16}}> {`${product?.AttName} : ${product?.Option}`} </p> )
                              )
                            }
                          </div>
                        </div>
                        <div>
                          <p className=" order-item-label ml-4 mt-4"> 
                            Note :  
                              {
                                (
                                  (
                                    // buyerPaysForReturnShipping => FALSE
                                    !activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping &&
                                    // isShipmentCreated => FALSE
                                    !activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                                  ) 
                                  || 
                                  (
                                    // buyerPaysForReturnShipping => TRUE
                                    activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping &&
                                    // isShipmentCreated => FALSE
                                    !activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                                  )
                                )  ? 
                                  ' Awaiting Shipment' 
                              : 
                                (
                                  (
                                    // buyerPaysForReturnShipping => TRUE
                                    activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping &&
                                    // isShipmentCreated => TRUE
                                    activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                                  ) 
                                  || 
                                  (
                                    // buyerPaysForReturnShipping => FALSE
                                    !activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping &&
                                    // isShipmentCreated => TRUE
                                    activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                                  )
                                ) &&
                                  ' Please return your item to our nearest drop-off location'
                              }  
                          </p>
                        </div>
                      </div>
                    </div>
                    {    
                      <div className="order-estimate-product order-estimate-buttons" style={{marginTop:10}}>
                        <div className="table-order-detail">
                          <div className="order-detail-img-content " >
                            <div className="order-detail-img-l"></div>
                              <div>
                                <div className="order-button-block">
                                  {activityResponse?.returnDetailsBuyer?.data?.labelContent &&
                                    <button className="list-button bg-none" onClick={() => convertAndDownloadPDF(activityResponse?.returnDetailsBuyer?.data?.labelContent,"label",activityResponse?.returnDetailsBuyer?.data?.labelContentType)} > Download label </button>
                                  }
                                  {activityResponse?.returnDetailsBuyer?.data?.billDocContent &&
                                    <button className="list-button bg-none" onClick={() => convertAndDownloadPDF(activityResponse?.returnDetailsBuyer?.data?.billDocContent,"bill",activityResponse?.returnDetailsBuyer?.data?.billDocContentType)} > Download bill </button>
                                  }
                                  {activityResponse?.returnDetailsBuyer?.data?.labelContent &&
                                    <button className="list-button bg-none" onClick={() => printPDF(activityResponse?.returnDetailsBuyer?.data?.labelContent) } > Print label </button>
                                  }
                                  {activityResponse?.returnDetailsBuyer?.data?.billDocContent &&
                                    <button className="list-button bg-none" onClick={() => printPDF(activityResponse?.returnDetailsBuyer?.data?.billDocContent) } > Print bill </button>
                                  }
                                  {activityResponse?.returnDetailsBuyer?.data?.qrDocContent &&
                                    <button className="list-button bg-none" onClick={() => convertAndDownloadPDF(activityResponse?.returnDetailsBuyer?.data?.qrDocContent,"qr_code",activityResponse?.returnDetailsBuyer?.data?.qrDocContentType) } > Download QR Code </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    }
                  </div>
                  { 
                    (
                      // buyerPaysForReturnShipping => TRUE
                      activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping && 
                      // isShipmentCreated => FALSE
                      !activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                    ) &&
                    <div className="reportForm">
                      <div className="buyer-contact-form">
                        <div className="buyer-contact-form-col report-buyer-padd">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-buyer"> Courier service :</label>
                                <span className="red-star">*</span>
                                {/* <select className="form-control input-buyer" name="reportCategory" value={courierService} style={{color:"black"}} onChange={(e) => onCourierChange(e)} > */}
                                <select className="form-control input-buyer" value={courierServiceData} onChange={(e) => setCourierServiceData(e.target.value)}  style={{color:"black"}}>
                                  <option value=""> Select manual service</option>
                                  {sellerProductResponse?.courierService?.data?.courierServicesDetails?.map((item) => 
                                    ( <option key={item?.id} value={item?.id}> {item?.name} </option> )
                                  )}
                                </select>
                                {/* {courierService!=="" && 
                                  <p className="order-product-text pt-3" style={{fontSize:15}}>
                                    <span className="order-item-label" style={{fontSize:15}}> Shipping cost :</span>{" "}
                                    <span style={{color:"black"}}> {activityResponse?.courierServiceCharges?.data && activityResponse?.courierServiceCharges?.data[0]?.price &&  "$ "+activityResponse?.courierServiceCharges?.data[0]?.price}</span>    
                                  </p>
                                } */}
                                { courierServiceData?.length === 0 && <p style={{color:"red", fontSize:12}} >*Required!</p> }
                              </div>
                            </div>
                            {/* <div className="col-md-4">
                              <div className="form-group">
                                <label className="label-modal" htmlFor="ShippingDateAndTime">Shipment date and time:</label>
                                <DatePicker
                                  selected={shippingDate && shippingDate}
                                  onChange={(date:any) => setShippingDate(date)}
                                  name="ShippingDateAndTime"
                                  className="custom-class"
                                  showIcon
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={1}
                                  timeCaption="time"
                                  dateFormat="dd/MM/yyyy h:mm aa"
                                  shouldCloseOnSelect={true}
                                  placeholderText="dd/mm/yyyy -- : -- --"
                                  toggleCalendarOnIconClick
                                />
                                { shippingDate === null && <p style={{color:"red", fontSize:12}} >*Required!</p> }
                                { shippingDate !== null && dateCheck(shippingDate) }
                                { (moment(shippingDate).isBefore(new Date()) || dateCheck(shippingDate)) && 
                                  <p style={{color:"red", fontSize:12}} >
                                    {(moment(shippingDate).isBefore(new Date()) || dateCheck(shippingDate)) && 'Shipping'} 
                                    date is either a past date or more than 10 days in the future.
                                  </p>
                                }
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <label className="label-modal">Tracking number :</label>
                              <input  type="text" placeholder="TRACK123" style={{width:"100%", padding:5, fontSize:14}} value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)}/>
                              { trackingNumber?.length === 0 && <p style={{color:"red", fontSize:12}} >*Required!</p> }
                              {/* { isNaN(trackingNumber) && <p style={{color:"red", fontSize:12}} >Tracking number must be in numeric format</p>} */}
                              { (courierServiceData == 1 && (trackingNumber?.length > 0 && (trackingNumber?.length < 10 || trackingNumber?.length > 11))) && <p style={{color:"red", fontSize:12}} >Tracking number must be minimum of length 10 and maximum of length 11</p> }
                            </div>
                            <div className="col-12">
                              <p style={{fontSize:12}}>(Note: "Manual" : To create a shipment manually, kindly visit the <Link to={"#"} target="_blank">link</Link>.)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="seller-button contact-buyer-btn">
                        {
                          (courierServiceData.length === 0 || trackingNumber?.length === 0 ) || 
                          (
                            (courierServiceData.length > 0 && courierServiceData == 1) && 
                            (trackingNumber?.length > 0 && (trackingNumber?.length < 10 || trackingNumber?.length > 11))
                          ) 
                        ?
                          <button type="submit" className="list-button ml-4">Submit</button>
                        :
                          <button type="submit" className="list-button ml-4" onClick={handleSubmit}>Submit</button>
                        }
                      </div>
                    </div>
                  }

                  { (
                      (
                        // buyerPaysForReturnShipping => TRUE
                        activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping && 
                        // isShipmentCreated => TRUE
                        activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                      )
                      ||
                      (
                        // buyerPaysForReturnShipping => FALSE
                        !activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping && 
                        // isShipmentCreated => TRUE
                        activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
                      )
                    ) 
                    &&
                    <>
                      {activityResponse?.returnOrderTrackingDetails && 
                        <div className="historyTable" style={{minHeight: "0",maxHeight:"250px",overflowY: "auto"}}>
                          <div className="row trackDetail">
                            <div className="col-md-auto mr-md-5 mb-3 mb-md-0">
                              <strong>Tracking number:</strong>
                              <span>{activityResponse?.returnOrderTrackingDetails?.trackingNumber}</span>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Status of Item</th>
                                  <th>Location</th>
                                </tr>
                              </thead>
                              <tbody>
                                {activityResponse?.returnOrderTrackingDetails?.data?.trackings?.map((item:any,index:number) =>
                                  (<tr key={index}>
                                    <td>{moment(item?.date).format("MMMM Do YYYY")}</td>
                                    <td>{item?.description}</td>
                                    <td>{item?.packageLocation?.map((itm:any) =>(itm?.description))}</td>
                                  </tr>)
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      }  
                    </>
                  }
                </div>
              </div>
              {(
                // buyerPaysForReturnShipping => TRUE
                activityResponse?.returnDetailsBuyer?.data?.buyerPaysForReturnShipping &&
                // isShipmentCreated => FALSE
                !activityResponse?.returnDetailsBuyer?.data?.isShipmentCreated
              ) &&
              <div className="col-xl-3 mt-5">
                <h4>Seller Address :</h4>
                <div className="order-item-label">
                  <b>{sellerAddress?.sellerName}</b>
                  <br /> {sellerAddress?.sellerAddress1}
                  <br /> {sellerAddress?.sellerAddress2}
                         {sellerAddress?.sellerZipCode}
                  <br /> {sellerAddress?.sellerState}
                  <br /> {sellerAddress?.sellerCountry}
                  {sellerAddress?.sellerMobileNo}
                </div>
              </div>
              }
            </div>
          </section>
        </div>
      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default ReturnDetailBuyer;
