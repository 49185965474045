import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { orderCancelled } from "../../../modules/orders/orderCancelled";
import { createShipmentSave } from "../../../modules/orders/createShipmentSave";
import moment from "moment";
import { getShipperBuyerDetail } from "../../../modules/orders/getShipperBuyerDetail";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { getCourierServiceDetails } from "../../../modules/sellerProduct/getCourierServiceDetails";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";

const CreateShipmentModel = ({createShipment, setCreateShipment }: any) => {
  const dispatch   = useDispatch();
  let order        = useSelector<RootState, RootState["sellerOrders"]>( SellerOrdersResponseSelector );
  const sellerResp = useSelector<RootState, RootState["sellerProduct"]>(SellerProductResponseSelector);
 
  const [isShipmentFromEfindit, setIsShipmentFromEfindit] = useState<boolean | null>(null);
  const [shippingDate,          setShippingDate]          = useState<any>(null);
  const [deliveryDate,          setDeliveryDate]          = useState<any>(null);
  
  useEffect(() =>{
    setIsShipmentFromEfindit(createShipment?.manualShipment);
    dispatch<any>(getCourierServiceDetails(createShipment?.orderId));
  },[createShipment?.manualShipment])

  const dateCheck =(value:Date)=>{
    const date1 = moment(new Date());
    const date2 = moment(value);
    
    // Calculate the absolute difference in hours between the two dates
    const hoursDifference = Math.abs(date2.diff(date1, 'hours'));
    
    // Calculate the total difference in days and time
    const daysDifference = Math.floor(hoursDifference / 24);
    
    const remainingHours = hoursDifference % 24;
    if (daysDifference > 10 || (daysDifference === 10 && remainingHours > 0)) {
      return true
    } else {
      return false
    }
  }

  const onChange = (e: any) => {
      setCreateShipment({
        ...createShipment,
        [e.target.name]: e.target.value,
      });
  };

  useEffect(()=>{
    if(createShipment.orderId){
      dispatch<any>(getShipperBuyerDetail({orderId:createShipment.orderId}))
    }
  },[createShipment.orderId])

  type defaultShipmentState = {
    "ShippingDateAndTime" : string, 
    "orderId"             : number,
    "ShipmentDescription" : string,
    "type"                : string
  }

  type manualShipmentState = {
    "ShippingDateAndTime" : string, 
    "DeliveryDateAndTime" : string, 
    "orderId"             : number,
    "ShipmentDescription" : string,
    "TrackingNumber"      : string,
    "type"                : string
  }

  const handleSubmit = () =>{
    let payload:defaultShipmentState | manualShipmentState | null

    if(isShipmentFromEfindit){
      payload = {
        "ShippingDateAndTime" : moment(new Date(shippingDate)).format('YYYY-MM-DDTHH:mm:ss Z'), 
        "orderId"             : createShipment.orderId,
        "ShipmentDescription" : createShipment.ShipmentDescription,
        "type"                : "default"
      }
    }else{
      payload = {
        "ShippingDateAndTime" : moment(new Date(shippingDate)).format('YYYY-MM-DDTHH:mm:ss Z'), 
        "DeliveryDateAndTime" : moment(new Date(deliveryDate)).format('YYYY-MM-DDTHH:mm:ss Z'), 
        "orderId"             : createShipment.orderId,
        "ShipmentDescription" : createShipment.ShipmentDescription,
        "TrackingNumber"      : createShipment.TrackingNumber,
        "type"                : "manual"
      }
    }

    dispatch<any>(createShipmentSave(payload));
    handleCancel();
  }

  const handleCancel = () =>{
    setCreateShipment({ ...createShipment, 
      visible                : "none", 
      ShippingDateAndTime    :'',
      orderId                : 0,
      manualShipment         : false,
    }) 

    setShippingDate(null);
    setDeliveryDate(null);
    
    let createShipmentExtraKeysArr:string[] = ['serviceName','ShippingDate','DeliveryDate','TrackingNumber'];
    removeKeys(createShipmentExtraKeysArr);
  }

  const removeKeys = (keysToRemove:any) => {
    setCreateShipment((prevState:any) => {
      const newState = { ...prevState };
      keysToRemove.forEach((key:any) => {
        delete newState[key]; // Remove key from the newState object
      });
      return newState; // Return the updated state object
    });
  };
  
//-------------------------------------------------------------------------------------------------------
  
  return (
    <div className="modal modal-order-popup" id="myModal" tabIndex={-1} role="dialog" style={{ display: createShipment.visible }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            
            <div className="order-modal-head border-none" style={{paddingBottom:'0.5rem'}}>Create shipment</div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group spacer-feedback-select">
                  <label className="label-modal" style={{fontWeight:"bold"}}>Buyer address</label>
                  <p style={{fontSize:12}}>
                    {order?.shippingBuyerDetail?.data?.buyerAddress1} <br />
                    {order?.shippingBuyerDetail?.data?.buyerAddress2 ?<> {order?.shippingBuyerDetail?.data?.buyerAddress2} <br /></> :null}  {" "}
                    {order?.shippingBuyerDetail?.data?.buyerCity}<br />
                    {order?.shippingBuyerDetail?.data?.buyerCountryName} <br />{" "}
                    {order?.shippingBuyerDetail?.data?.buyerZipCode}<br />
                    {order?.shippingBuyerDetail?.data?.buyerMobileNo}<br />
                  </p>                  
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group spacer-feedback-select">
                  <label className="label-modal" style={{fontWeight:"bold"}}>Seller address</label>
                  <p style={{fontSize:12}}>
                    {order?.shippingBuyerDetail?.data?.sellerAddress1} <br />
                    {order?.shippingBuyerDetail?.data?.sellerAddress2 ?<> {order?.shippingBuyerDetail?.data?.sellerAddress2} <br /></> :null}  {" "}
                    {order?.shippingBuyerDetail?.data?.sellerCity}<br />
                    {order?.shippingBuyerDetail?.data?.sellerCountryName} <br />{" "}
                    {order?.shippingBuyerDetail?.data?.sellerZipCode}<br />
                    {order?.shippingBuyerDetail?.data?.sellerMobileNo}<br />
                  </p>                  
                </div>
              </div>
            </div>

            {!isShipmentFromEfindit &&
              <>
                <div className="form-group">
                  <label className="label-modal">Shipment from:</label>
                  <p style={{fontSize:12}}>(Note: "Manual" : To create a shipment manually, kindly visit the <Link to={"#"} target="_blank">link</Link>.)</p>
                  <select className="form-control input-modal" value={createShipment?.serviceName} name='serviceName' style={{width:"100%", padding:4, fontSize:14}} onChange={onChange}>
                    <option value=''>Select manual service</option>
                    {order?.shippingBuyerDetail?.data?.courierServicesName?.map((item) => 
                      ( <option key={item.id} value={item.id}> {item.name} </option> )
                    )}
                  </select>
                  {(!createShipment?.serviceName || createShipment?.serviceName?.value === '') && <p style={{color:"red"}} >*Required!</p>}
                </div>
                  
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-modal" htmlFor="ShippingDateAndTime">Shipment date and time:</label>
                      <DatePicker
                        selected={shippingDate && shippingDate}
                        onChange={(date:any) => setShippingDate(date)}
                        name="ShippingDateAndTime"
                        className="custom-class"
                        showIcon
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy h:mm aa"
                        shouldCloseOnSelect={true}
                        placeholderText="dd/mm/yyyy -- : -- --"
                        toggleCalendarOnIconClick
                      />
                      {shippingDate === null && <p style={{color:"red"}} >*Required!</p>}
                      { shippingDate !== null && dateCheck(shippingDate)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-modal" htmlFor="DeliveryDateAndTime">Expected delivery date:</label>
                      <DatePicker
                        selected={deliveryDate && deliveryDate}
                        onChange={(date:any) => setDeliveryDate(date)}
                        name="DeliveryDateAndTime"
                        className="custom-class"
                        showIcon
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy h:mm aa"
                        shouldCloseOnSelect={true}
                        placeholderText="dd/mm/yyyy -- : -- --"
                        toggleCalendarOnIconClick
                      />
                      {deliveryDate === null && <p style={{color:"red"}} >*Required!</p>}
                      { deliveryDate !== null && dateCheck(deliveryDate)}
                    </div>
                  </div>
                  <div className="col-12">
                    { (
                        (moment(shippingDate).isBefore(new Date()) || dateCheck(shippingDate)) || 
                        (moment(deliveryDate).isBefore(new Date()) || dateCheck(deliveryDate))
                      )  
                    && 
                      <p style={{color:"red", fontSize:10}} >
                        {
                          (moment(shippingDate).isBefore(new Date()) || dateCheck(shippingDate)) ? 
                            'Shipping' 
                        : 
                          (moment(deliveryDate).isBefore(new Date()) || dateCheck(deliveryDate)) && 
                            'Delivery'
                        } date is either a past date or more than 10 days in the future.
                      </p>
                    }
                    <p style={{fontSize: 12}}>(Note: Date should not be in the past and it should be within 10 days from the current date.)</p>
                  </div>
                </div>
                 
                <div className="form-group">
                <label className="label-modal">Tracking number:</label>
                  <input name="TrackingNumber" type="text" placeholder="TRACK123" value={createShipment?.TrackingNumber} style={{width:"100%", padding:5, fontSize:14}} onChange={onChange}/>
                  {(!createShipment?.TrackingNumber || createShipment?.TrackingNumber?.length === 0) && <p style={{color:"red"}} >*Required!</p>}
                </div>

                {/* <div className="form-group spacer-feedback-select">
                    <div className="row">
                      <div className="col-md-6">
                        <button className="list-button bg-none" style={{width:"100%" , padding:4, fontSize:14}}>Upload label</button>
                      </div>
                      <div className="col-md-6">
                        <button className="list-button bg-none" style={{width:"100%" , padding:4, fontSize:14}}>Upload bill</button>
                      </div>
                    </div>
                </div> */}
              </>
            }
            {isShipmentFromEfindit &&
              <div className="form-group spacer-feedback-select">
                <label className="label-modal">Shipment date and time</label>
                <p style={{fontSize:12}}>(Note: Date should not be in past and it should be within 10 days from current date.)</p>
                <DatePicker
                  selected={shippingDate && shippingDate}
                  onChange={(date:any) => setShippingDate(date)}
                  name="ShippingDateAndTime"
                  className="custom-class"
                  showIcon
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="time"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  shouldCloseOnSelect={true}
                  placeholderText="dd/mm/yyyy -- : -- --"
                  toggleCalendarOnIconClick
                />
                { shippingDate === null &&  <p style={{color:"red"}} >*Required!</p>}
                { dateCheck(shippingDate)}
                { (moment(shippingDate).isBefore(new Date()) ||  dateCheck(shippingDate))  && 
                  <p style={{color:"red", fontSize:10}} >The entered date is either a past date or more than 10 days in the future.</p>
                }
              </div>
            }

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Description</label>
              <textarea className="form-control buyer-textarea input-modal" rows={3} maxLength={500} placeholder="Write something" value={ createShipment.ShipmentDescription }
                onChange={(e) =>
                  setCreateShipment({
                    ...createShipment,
                    ShipmentDescription: e.target.value ,
                  })
                }>
              </textarea>
              { createShipment?.ShipmentDescription?.length===0 &&  <p style={{color:"red"}} >*Required!</p>}
              <p>{createShipment.ShipmentDescription === null ? 0 : createShipment?.ShipmentDescription?.length} /500</p>
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button className="modal-add-btn modal-cancel-order-btn" 
                onClick={handleSubmit}
                disabled={ 
                  createShipment.ShipmentDescription.length === 0 || 
                  shippingDate === "" ||
                  (!isShipmentFromEfindit &&
                  deliveryDate === "" ||
                  createShipment.serviceName === "" ||
                  createShipment.TrackingNumber === "" )
                  ? true : false 
                } 
                style={
                    createShipment.ShipmentDescription.length === 0 || 
                    shippingDate === "" ||
                    (!isShipmentFromEfindit &&
                    deliveryDate === "" ||
                    createShipment.serviceName === "" ||
                    createShipment.TrackingNumber === "" )
                    ? {cursor:'not-allowed'} : {}
                }>Create</button>
              <button className="modal-cancel-btn" onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateShipmentModel;
