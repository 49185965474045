import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postContactBuyer } from "../../../modules/orders/postContactBuyer";
import ImageUploading from "react-images-uploading";
import { removeImage } from "../../../modules/sellerProduct/removeImage";
import ADD from "../../../assets/images/add.png";
import CROSS from "../../../assets/images/cross.png";
import pdfIcon from "../../../assets/images/pdfIcon.png"
import { Link } from "react-router-dom";
import { getCourierServiceDetails } from "../../../modules/sellerProduct/getCourierServiceDetails";
import { RootState } from "../../../utils/types";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";

const ContactBuyer = ({ contactBuyer, setContactBuyer }: any) => {
  const dispatch = useDispatch();
  const [error, setError]=useState("")
  const [images, setImages] = React.useState<any>([]);
  const [imgErr, setImgError]=useState<boolean>(false)
  const maxNumber = 2;

  useEffect(() =>{
    dispatch<any>(getCourierServiceDetails());
  },[])

  const BuyerResp = useSelector<RootState, RootState["sellerProduct"]>(SellerProductResponseSelector);
 
  const handleSubmit =()=>{
    if(contactBuyer.message=="" && (contactBuyer?.paidBySeller !== undefined && images.length === 0)){ 
      setError("*Required!");
      setImgError(true);
    }else if(contactBuyer?.paidBySeller !== undefined && images.length === 0){
      setImgError(true);
    }else if(contactBuyer.message==""){  
      setError("*Required!");
      setImgError(false); 
    }else{
      setImgError(false);
      let finalPayload:any=contactBuyer;
      if(contactBuyer?.paidBySeller !== undefined && images.length > 0){
        let formData = new FormData();
        formData.append("shipmentLabelDto", JSON.stringify({
                                                            ...contactBuyer,
                                                            trackingNumber   : contactBuyer?.trackingNumber,
                                                            courierServiceId : contactBuyer?.courierServiceId ? contactBuyer?.courierServiceId : 1
                                                          }));
        for (var a = 0; a < images.length; a++) {
          if (images[a]["file"] !== undefined)
            formData.append("photos", images[a]["file"]);
        }
         finalPayload = {
                          form_data    : formData,
                          paidBySeller : contactBuyer?.paidBySeller
                        }
      }
      
      dispatch<any>(postContactBuyer(finalPayload));
      setContactBuyer({ ...contactBuyer, visible: "none",message:'' });
      setImages([])
    }
  }

  const onImageChange = (imageList: any, addUpdateIndex: any) => {
    if (images.length === imageList.length && addUpdateIndex !== undefined) {
      if (images[addUpdateIndex].id !== undefined)
        dispatch<any>(removeImage(images[addUpdateIndex]));
    }
    setImages(imageList);
  };

//------------------------------------------------------------------------------------------------------  
  
  return (
    <div className="modal modal-order-popup" id="myModal" tabIndex={-1} role="dialog" style={{ display: contactBuyer.visible }} >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">{contactBuyer?.paidBySeller !== undefined ? 'Share label' :'Contact buyer'}</div>
            {
              contactBuyer?.paidBySeller &&
              <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="label-buyer"> Courier service :</label>
                      <span className="red-star">*</span>
                      <select className="form-control input-buyer" name="reportCategory" style={{color:"black"}} onChange={(e) => setContactBuyer({...contactBuyer,courierServiceId:e.target.value})}>
                        <option value=""> Choose Type</option>
                        {BuyerResp?.courierService?.data?.courierServicesDetails?.map((item) => 
                          ( <option key={item.id} value={item.id}> {item.name} </option> )
                        )}
                      </select>
                      {(!contactBuyer?.courierServiceId || contactBuyer?.courierServiceId.value === '') && <p style={{color:"red"}} >*Required!</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="label-modal">Tracking number :</label>
                    <input type="text" placeholder="TRACK123" style={{width:"100%", padding:5, fontSize:14}} onChange={(e) => setContactBuyer({...contactBuyer,trackingNumber:e.target.value})}/>
                    {(!contactBuyer?.trackingNumber || contactBuyer?.trackingNumber.value === '') && <p style={{color:"red"}} >*Required!</p>}
                  </div>
                  <div className="col-12">
                    <p style={{fontSize:12}}>(Note: "Manual" : To create a shipment manually, kindly visit the <Link to={"#"} target="_blank">link</Link>.)</p>
                  </div>
                </div>
                <div>
                  <ImageUploading multiple value={images} onChange={onImageChange} maxNumber={maxNumber} dataURLKey="data_url" acceptType={["jpg", "png", "pdf"]} maxFileSize={10000000} allowNonImageType={true}>
                    {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors, }) => 
                      (
                        <div className="upload__image-wrapper">
                          <label className="label-seller">
                            <span className="red-star">*</span> Add images or files (Allowed format:jpg,png,pdf)
                          </label>&nbsp;
                          {imageList.length < 2 &&
                          <button className="btn btn-primary" style={isDragging ? { color: "red" } : undefined} type="button" onClick={onImageUpload} {...dragProps} >
                            Click or drop here
                          </button>
                          }
                          <h6>Max files:{maxNumber}</h6>&nbsp;
                          <div className="d-flex image-flex-wrapper">
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item ">
                                <img src={image?.file?.name.split('.').pop() === 'pdf' ? pdfIcon : image["data_url"]} alt="" width="100" height="101" />
                                <div className="d-flex justify-content-between">
                                  <button type="button" className="btn btn-primary" onClick={(e) => { onImageUpdate(index); }} >Update</button>
                                  <div className="remove-img-close" style={{ cursor: "pointer", }} onClick={() => { if (image.id !== undefined) dispatch<any>(removeImage(image)); onImageRemove(index); }} >
                                    <img src={CROSS} alt="" />
                                  </div>
                                </div>
                                <div title={image?.file?.name} style={{cursor:"pointer"}}>{image?.file?.name && image?.file?.name.length > 15 ? image?.file?.name.substring(0,15) + '...' : image?.file?.name}</div>
                              </div>
                            ))}
                            {imageList.length < 2 && (
                              <div className="add-img-upload d-flex" style={ isDragging ? { color: "red" } : undefined } onClick={onImageUpload} {...dragProps} >
                                <img src={ADD} alt="" width="100" height="120" style={{ cursor: "pointer", }} />
                              </div>
                            )}
                            {errors?.acceptType && ( <span style={{color:"red", fontSize:12}}> &nbsp; This file extension is not allowed </span> )}
                            {errors?.maxFileSize && ( <span style={{color:"red", fontSize:12}}> &nbsp; Selected file size exceed maxFileSize 10 MB </span> )}
                            {errors?.maxNumber && ( <span style={{color:"red", fontSize:12}}> &nbsp; Maximum (2) two files are allowed </span> )}
                            {imgErr && (<span style={{color:"red", fontSize:12}}> &nbsp;File required </span>)}
                          </div>
                        </div>
                      )
                    }
                  </ImageUploading>
                </div>
              </>
            }
            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Write to buyer </label>
              <textarea className="form-control buyer-textarea input-modal" rows={3} maxLength={500} placeholder="write some thing" value={contactBuyer.message} onChange={(e) => { setContactBuyer({ ...contactBuyer, message: e.target.value, }); setError(""); } }></textarea>
              <p>{contactBuyer.message.length}/500</p>
              <p style={{color:"red", fontSize:12}}>{error && error}</p>
            </div>
            <div className="buyer-checkbox-items">
              <p>
                <input type="checkbox" id="checkbox4" checked={contactBuyer.sendCopy} onChange={(e: any) => setContactBuyer({ ...contactBuyer, sendCopy: e.target.checked, }) } name="radio-group" />
                <label htmlFor="checkbox4">Send a copy to my email address</label>
              </p>
            </div>
            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn"
                onClick={() => { handleSubmit() }}
                // disabled={contactBuyer.message === "" ? true : false}
              >
                Submit
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() => {setContactBuyer({ ...contactBuyer, visible: "none",message:'' });setImages([])} } >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBuyer;
