import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { getWatchlist } from "../../../../../modules/buyer/myEfindit/activity/getWatchlist";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import DefaultImage from "../../../../../assets/images/defaultImage.png";
import moment from "moment";
import NoteBidsOffersModel from "../NoteBidsOffersModel";
import { notify } from "../../../../../modules/notification/notify";
import { constant } from "../../../../../utils/constant";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { deleteWatchlist } from "../../../../../modules/buyer/myEfindit/activity/deleteWatchlist";
import { resetDetail } from "../../../../../modules/buyer/product/resetDetail";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../../../../utils/helpers";
import BuyerFooter from "../../../buyerFooter";

const Watchlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );

  const [filter, setFilter] = useState<any>("");
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any>([]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setIsCheckAll(!isCheckAll);

    if (checked) {
      setIsCheck(
        activityResponse?.watchlist?.data?.products?.map((li) => li.watchListId)
      );
    } else setIsCheck([]);
  };

  const handleDeleteAll = () => {
    dispatch<any>(
      deleteWatchlist({
        ids: isCheck,
      })
    );
  };
  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    if (
      checked &&
      isCheck.length === activityResponse?.watchlist?.data?.products.length - 1
    ) {
      setIsCheckAll(true);
    }
    setIsCheck([...isCheck, Number(id)]);
    if (!checked) {
      setIsCheckAll(false);

      setIsCheck(isCheck.filter((item: any) => item !== Number(id)));
    }
  };

  interface NoteValues {
    visible: string;
    Id: string | number;
    Note: string;
  }
  const [note, setNote] = useState<NoteValues>({
    visible: "none",
    Id: "",
    Note: "",
  });

  if (activityResponse.watchlist !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(activityResponse?.watchlist?.data?.totalResult / itemCount)
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const callWatchlist = () => {
    dispatch<any>(
      getWatchlist({
        currentPage: currentPage + 1,
        rows: itemCount,
        status: filter,
      })
    ).then(() => {
      window.scrollTo(0, 0);

      if (currentPage === 0) setPageCount(0);
    });
  };

  useEffect(() => {
    if (activityResponse.saveDataProduct != null) {
      if (activityResponse.saveDataProduct.success == "Y") {
        notify(activityResponse.saveDataProduct.message, constant.SUCCESS);
        callWatchlist();
      } else if (activityResponse.saveDataProduct.success == "N") {
        notify(activityResponse.saveDataProduct.message, constant.ERROR);
      }
    }
    if (activityResponse.saveDataError !== null) {
      notify(activityResponse.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.saveDataProduct, activityResponse.saveDataError]);

  useEffect(() => {
    if (activityResponse.noteSave != null) {
      if (activityResponse.noteSave.success == "Y") {
        notify(activityResponse.noteSave.message, constant.SUCCESS);
        callWatchlist();
      } else if (activityResponse.noteSave.success == "N") {
        notify(activityResponse.noteSave.message, constant.ERROR);
      }

      setNote({
        visible: "none",
        Id: "",
        Note: "",
      });
    }
    if (activityResponse.noteError !== null) {
      notify(activityResponse.noteError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.noteSave, activityResponse.noteError]);
  useEffect(() => {
    if (!isFirstRender2.current) {
      callWatchlist();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);
  useEffect(() => {
    if (isFirstRender.current) {
      callWatchlist();
    }
    isFirstRender.current = false;
  }, []);
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
      <NoteBidsOffersModel
        note={note}
        setNote={setNote}
        checked={"Watchlist"}
      />
      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My eFindit</h1>

          <GroupTab currentPage="Activity" />
          <section className="withFilter">
            <ActivityNavPanel currentPage="Watchlist" />

            <div className="right-wrapper">
              <section className="deals-group pt-0">
                <div className="deal-group-list four-col bgLight mt-0">
                  <div className="right-wrap-title-block">
                    <div className="row align-items-center">
                      <div className="col-md-auto">
                        <div className="right-wrap-title">Watchlist</div>
                      </div>
                    </div>
                  </div>
                  <div className="bidListCont watchListBlock">
                    <div className="watch-list-row-container">
                      {activityResponse?.watchlist?.data !== null && (
                       <div className="d-flex justify-content-between">  
                        <div className="watch-list-delete-block">
                              <div className="custom-check">
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    name="selectAll"
                                    id="allchecked"
                                    onChange={handleSelectAll}
                                    checked={isCheckAll}
                                  />
                                  <label htmlFor="allchecked"></label>
                                </div>
                              </div>
                            <div>
                              {" "}
                              {/* <button className="addTolistBtn">
                                Add to list
                              </button> */}
                              <button
                                className="deleteBtn"
                                onClick={handleDeleteAll}
                                disabled={isCheck.length === 0 ? true : false}
                              >
                                Delete
                              </button>
                            </div>
                         
                          </div>
                          <div className="sortSelect d-inline-block ">
                        <label htmlFor="">See items from:</label>
                        <div className="selectBox">
                          <select
                            name=""
                            id=""
                            onChange={(e) =>
                              setFilter( e.target.value!=="" ? parseInt(e.target.value) :"")
                            }
                          >
                            <option value="">Select</option>
                            <option value="30">Last 30 days</option>
                            <option value="60">Last 60 days</option>
                          </select>
                        </div>
                      </div>
                     </div>
                      )}

                              
                  <div className="watch-list-block">
                  {activityResponse?.watchlist?.data?.products?.map(
                          (product, Index) => {
                            const dateIsAfter = moment(new Date()).isAfter(
                              moment(product.listingExpiryDate)
                            );
                            return (
                              <div className="watch-list-row" key={Index + product.productId}>

                              <div className="watch-list-content">
      
                                <div className="watch-list-image-info">
                                  <div className="watch-list-check">
                                    <div className="custom-check">
                                      <div className="wrap">
                                      <input
                                          type="checkbox"
                                          name={product.title}
                                          id={product.watchListId.toString()}
                                          onChange={handleCheckboxClick}
                                          checked={isCheck.includes(
                                            product.watchListId
                                          )}
                                        />
                                        <label
                                          htmlFor={product.watchListId.toString()}
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
      
                                  <div className="watch-list-image" style={{cursor:"pointer"}} onClick={() =>
        dispatch<any>(
          resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}/${product.variantId}`))      
      }>

                                <picture>
                                  <source srcSet={getWebPImage(product.defaultImage)} type="image/webp"/>
                                  <LazyLoadImage
                                    className="prod-img"
                                    src={BASE_URLS.AWS_URL +
                                      "/Thumb" +
                                      product.defaultImage}
                                      onError={imageOnError}
                                    />
                                </picture>

                                  </div>  
                                </div>
      
                                <div className="watch-list-info">
                                <h4 className="itemTitle" onClick={() =>
                                  dispatch<any>(
                                    resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}/${product.variantId}`))      
                                } >{product.title}</h4>
                                        {dateIsAfter && (
                                          <span className="text-danger itemTitle">
                                            Ended
                                          </span>
                                        )}
                                        <div className="itemNumber">
                                          Condition: {product.condition}

                                          {
                                        JSON.parse(product.variant)?.map((item:any)=>(
                                        <p> {`${item.AttName} : ${item.Option}`} </p>
                                        ))

                                      }
                                        </div>
      
                                  <div className="watch-list-prices">
                                      <div className="watch-list-col one">
                                        <div className="priceCols p-0">
                                          <div className="priceTitle">ITEM PRICE:</div>
                                          <div className="priceTxt">${product.buyNowPrice? product.buyNowPrice : product.actualPrice}</div>
                                          <div className="low"> +C ${product.actualPrice}</div>
                                        </div>
                                      </div>
                                      
                                      <div className="watch-list-col three">
                                        <div className="priceCols">
                                          <div className="priceTitle">SELLER:</div>
                                          <div className="sellerTitleLink">
                                         
                                  <Link  to={`/buyer/sellerStore/${product.sellerId}`}>   {product.storeName}</Link>

                                          </div>
                                          <div className="low mt-2">{product.feedback}% positive feedback</div>
                                        </div>
                                      </div>
                                  </div>
                                  
                                  <div className="tags">
                                    {!dateIsAfter ? (
                                      <>
                                        {product.quantity < constant?.ALMOST_GONE_QUANTITY && (
                                          <span className="redTxt">
                                            Almost gone
                                          </span>
                                        )}
                                        <span>{product.sold ?  product.sold + " " : "0"} sold</span>
                                        <span>{product.watching +" "} watching</span>
                                      </>
                                    ) : (
                                      <span className="redTxt">Ended</span>
                                    )}
                                  </div>

                             {  product.note  &&   <div className="mt-2">
                                     <h5> Note to self:</h5>
                                  <span className="text-break">{product.note? product.note + " " :""}</span>
                                  <br/>
                                      { (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setNote({
                                              ...note,
                                              visible: "block",
                                              Id: product.watchListId,
                                              Note:
                                                product.note === null
                                                  ? ""
                                                  : product.note,
                                            });
                                          }}
                                          className="editNote"
                                        >
                                          Edit note
                                        </span>
                                      )}
                                  </div>  }
      
                                </div>
      
                              </div>
      
                              <div className="watch-list-button">
                              {dateIsAfter ? (
                                    <>
                                      {" "}
                                  <Link  to={`/buyer/sellerStore/${product.sellerId}`}className="blueBorderBtn"> View seller's other items</Link>
                                    
                                      
                                    </>
                                  ) : (
                                   <> 
                                   {/* <button onClick={() =>
                                    dispatch<any>(
                                      resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}/${product.variantId}`))      
                                  } className="viewSeller">
                                      Buy it now
                                    </button> */}

                                 
                                  <Link  to={`/buyer/sellerStore/${product.sellerId}`}className="blueBorderBtn"> View seller's other items</Link>

                                    </>
                                  )}
                                  {/* <a href="" className="blueBorderBtn">
                                    View Seler Other Item
                                  </a> */}
                                  {/* <div className="selectBox">
                                    <select name="" id="">
                                      <option value="">More Action</option>
                                      <option value="">More Action</option>
                                    </select>
                                  </div> */}
                                  <div
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setNote({
                                        ...note,
                                        visible: "block",
                                        Id: product.watchListId,
                                        Note:
                                          product.note === null
                                            ? ""
                                            : product.note,
                                      });
                                    }}
                                    className="blackBorderBtn"
                                  >
                                    Note to self
                                  </div>

                              </div>
      
                            </div>
                      );
                      }
                    )}    
                  </div>        

                      
                    </div>
                  </div>

                  {activityResponse?.watchlist?.data != null && (        
                  <nav className="pagination-block">
                    <p className="total">
                      {activityResponse?.watchlist?.data?.totalResult} items
                      total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                  )}
                </div>
              </section>
            </div>
          </section>
        </div>
      </main>

   <BuyerFooter/>
    </DefaultLayout>
  );
};

export default Watchlist;
